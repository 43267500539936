<template>
  <div class="yt-main">
    <div class="personnel-info">
      <div class="top info">
        <h1>个人练习报告</h1>
        <div class="details">
          <div class="details-left">
            <span>基本信息</span>
            <div class="information">
              <p v-for="data in essential">{{ data.label }}{{ data.data }}</p>
            </div>
          </div>
          <div class="details-right">
            <span>练习得分情况</span>
            <div ref="scoreEcharts" class="columnar-s" v-loading="scoreStatus" element-loading-text="加载中" />
          </div>
        </div>
      </div>
      <div class="bottom info">
        <h1>练习结果分析</h1>
        <div class="details" style="min-height: 279px;">
          <div class="details-left">
            <div ref="resultEcharts" class="columnar-s" v-loading="resultStatus" element-loading-text="加载中" />
          </div>
          <div class="details-right" style="padding: 20px">
            <el-table
              ref="paperBankList"
              class="yt-table"
              height="100%"
              :data="practiceList"
              v-loading="practiceLoading"
              element-loading-text="加载中"
            >
              <el-table-column label="类目名称" prop="knowledgeName" show-overflow-tooltip />
              <el-table-column label="平均掌握率" prop="avgGrasp" show-overflow-tooltip>
                <template slot-scope="scope">{{ scope.row.avgGrasp * 100 }}%</template>
              </el-table-column>
              <el-table-column label="掌握率" prop="grasp" show-overflow-tooltip>
                <template slot-scope="scope">{{ scope.row.grasp * 100 }}%</template>
              </el-table-column>
              <el-table-column label="差异" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span :style="{ color: scope.row.isPlus ? '#4CE47A' : '#FF6060' }"
                    >{{ `${scope.row.isPlus ? '+' : '-'}${scope.row.difference * 100}` }}%</span
                  ></template
                >
              </el-table-column>
              <div slot="empty" id="empty-slot">
                <template v-if="!practiceLoading">
                  <YTIcon :href="'#icon-zanwushuju'" style="font-size: 100px" />
                </template>
              </div>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { columnarJS, resultAnalysis } from '@util/charts/Situation'
import practiceApi from '@api/practice'
import YTIcon from '@components/common/YTIcon'
export default {
  name: 'PersonnelPractice',
  components: { YTIcon },
  data() {
    return {
      scoreStatus: true,
      scoreEchart: this.$deepCopy(columnarJS), //得分情况图
      resultAnalysis: this.$deepCopy(resultAnalysis), //结果分析图
      essential: {
        realName: {
          label: '姓名：',
          data: ''
        },
        jobNumber: {
          label: '工号：',
          data: ''
        },
        score: {
          label: '练习分数：',
          data: ''
        },
        ranking: {
          label: '练习排名：',
          data: ''
        },
        averageScore: {
          label: '练习平均分：',
          data: ''
        },
        startTime: {
          label: '练习时间：',
          data: ''
        },
        duration: {
          label: '练习用时：',
          data: ''
        },
        practiceName: {
          label: '练习名称：',
          data: ''
        }
      },
      practiceList: [],
      practiceLoading: true,
      scoreEcharts: null,
      resultEcharts: null,
      resultStatus: true
    }
  },
  mounted() {
    this.scoreEcharts = this.$echarts.init(this.$refs['scoreEcharts'])
    this.resultEcharts = this.$echarts.init(this.$refs['resultEcharts'])
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeEcharts, true)
  },
  methods: {
    //获取详情
    getInfo(data) {
      this.getPersonalInfo(data.answerPaperId)
      this.setScoreEchart(data.answerPaperId)
      this.getResultAnalysis(data.answerPaperId)
      window.addEventListener('resize', this.resizeEcharts, true)
    },
    //得分情况图
    setScoreEchart(data) {
      this.scoreEchart.xAxis.data = []
      this.scoreEchart.series[0].data = []
      this.scoreEchart.series[1].data = []
      practiceApi
        .getScoreInfo(data)
        .then(res => {
          if (res.code === 0) {
            for (let data of res.res) {
              this.scoreStatus = false
              this.scoreEchart.xAxis.data.push(this.ytConstant.questionType.getLabel(data.questionType))
              this.scoreEchart.series[0].data.push(data.rightScore)
              this.scoreEchart.series[1].data.push(data.wrongScore)
            }
          }
        })
        .finally(() => {
          this.scoreEcharts.setOption(this.scoreEchart)
        })
    },
    //图表自适应
    resizeEcharts() {
      this.scoreEcharts.resize()
      this.resultEcharts.resize()
    },
    //结果分析图
    setResultAnalysis(data) {
      this.resultAnalysis.radar.indicator = []
      this.resultAnalysis.series[0].data[0].value = []
      this.resultAnalysis.series[0].data[1].value = []
      for (let datum of data) {
        this.resultStatus = false
        this.resultAnalysis.radar.indicator.push({ name: datum.knowledgeName, max: 100 })
        this.resultAnalysis.series[0].data[0].value.push(datum.grasp * 100)
        this.resultAnalysis.series[0].data[1].value.push(datum.avgGrasp * 100)
      }
      this.resultEcharts.setOption(this.resultAnalysis)
    },
    //练习个人结果分析图加列表
    getResultAnalysis(data) {
      practiceApi.getResultAnalysis(data).then(res => {
        if (res.code === 0) {
          this.practiceList = res.res
          this.practiceLoading = false
          this.setResultAnalysis(res.res)
        }
      })
    },
    //个人练习基本信息
    getPersonalInfo(data) {
      practiceApi.getPersonalInfo(data).then(res => {
        if (res.code === 0) {
          for (let key of Object.keys(this.essential)) {
            if (Object.prototype.hasOwnProperty.call(res.res, key)) {
              this.essential[key].data = key === 'startTime' ? this.$formatTime(res.res[key], 'yyyy-MM-dd hh:mm') : res.res[key]
            }
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.personnel-info {
  padding: 10px 10px 20px;
  .info {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    padding: 10px;
    h1 {
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      color: #333333;
      margin-bottom: 24px;
    }
    .details {
      display: flex;
      .details-left {
        flex: 1;
        border-right: 1px solid #e2e4e8;
        span {
          font-weight: bold;
          line-height: 20px;
          font-size: 14px;
          color: #333333;
          margin-left: 10px;
        }
        .information {
          display: flex;
          flex-wrap: wrap;
          margin-top: 20px;
          p {
            width: 100% / 2;
            padding-left: 40px;
            line-height: 20px;
            font-weight: 400;
            margin-bottom: 24px;
            color: #000000;
          }
        }
      }
      .columnar-s {
        margin-left: 40px;
        height: 100%;
      }
      .details-right {
        flex: 1;
        width: 100%;
        span {
          font-weight: bold;
          line-height: 20px;
          color: #333333;
          font-size: 14px;
          margin-left: 10px;
        }
      }
    }
  }
  .top {
    min-height: 264px;
  }
  .bottom {
    margin-top: 10px;
    min-height: 279px;
  }
}
</style>
