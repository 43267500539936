const columnarJS = {
  color: ['#6BA7F5', '#FF6060'],
  legend: {
    data: ['得分', '失分'],
    left: '74%'
  },
  tooltip: {},
  xAxis: {
    data: [],
    name: '',
    axisLine: { onZero: true },
    splitLine: { show: false },
    splitArea: { show: false }
  },
  yAxis: {},
  grid: {
    top: '25%',
    left: '5%',
    bottom: '20',
    right: '5%',
    containLabel: true
  },
  series: [
    {
      name: '得分',
      type: 'bar',
      stack: 'total',
      label: {
        color: '#FFFFFF',
        fontSize: '10px',
        show: true
      },
      data: []
    },
    {
      name: '失分',
      type: 'bar',
      stack: 'total',
      label: {
        color: '#FFFFFF',
        fontSize: '10px',
        show: true
      },
      data: []
    }
  ]
}

//结果分析图
const resultAnalysis = {
  legend: {
    data: ['掌握率', '平均掌握率'],
    right: 90,
    orient: 'vertical',
    itemGap: 10,
    itemWidth: 6,
    itemHeight: 6
  },
  tooltip: {
    trigger: 'axis'
  },
  color: ['#7CAEEF', '#F16E57'],
  radar: {
    indicator: [],
    name: {
      textStyle: {
        color: '#000000'
      }
    }
  },
  series: [
    {
      type: 'radar',
      data: [
        {
          value: [],
          name: '掌握率'
        },
        {
          value: [],
          name: '平均掌握率'
        }
      ]
    }
  ]
}
//题目数量图
const topicQuantity = {
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b} : {c} ({d}%)'
  },
  series: [
    {
      type: 'pie',
      radius: ['40%', '70%'],
      data: [
        { value: 1048, name: 'Search Engine' },
        { value: 735, name: 'Direct' },
        { value: 580, name: 'Email' },
        { value: 484, name: 'Union Ads' },
        { value: 300, name: 'Video Ads' }
      ]
    }
  ]
}
//分数分布图
const fractional = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
      crossStyle: {
        color: '#999'
      }
    }
  },
  grid: {
    top: '10%',
    left: '2%',
    right: '2%',
    bottom: '2%',
    containLabel: true
  },
  legend: {
    data: ['人数', '比率'],
    right: 60
  },
  color: ['#FFBB00', '#448BFF'],
  xAxis: [
    {
      type: 'category',
      data: [],
      axisPointer: {
        type: 'shadow'
      }
    }
  ],
  yAxis: [
    {
      type: 'value',
      min: 0,
      max: 250,
      interval: 50,
      axisLabel: {
        formatter: '{value}'
      }
    },
    {
      type: 'value',
      min: 0,
      max: 100,
      interval: 5,
      axisLabel: {
        formatter: '{value} %'
      }
    }
  ],
  series: [
    {
      name: '人数',
      type: 'bar',
      data: []
    },
    {
      name: '比率',
      type: 'line',
      label: {
        normal: {
          show: true,
          position: 'top',
          formatter: '{c}' + '%'
        }
      },
      yAxisIndex: 1,
      data: []
    }
  ]
}

//掌握情况图
const masterMap = {
  title: [
    {
      text: '知识点',
      left: 0,
      textStyle: {
        //字体粗细
        fontWeight: 'bold',
        //字体大小
        fontSize: 12
      }
    },
    {
      text: '平均掌握率',
      right: 0,
      textStyle: {
        //字体粗细
        fontWeight: 'bold',
        //字体大小
        fontSize: 12
      }
    }
  ],
  grid: {
    top: '10%',
    left: '1%',
    bottom: '1%',
    containLabel: true
  },
  tooltip: {
    trigger: 'item',
    formatter: '{b} : {c}%'
  },
  color: ['#B7D8F2'],
  xAxis: {
    type: 'value',
    min: 0,
    max: 100,
    interval: 10,
    axisLabel: {
      formatter: '{value} %'
    }
  },
  yAxis: {
    type: 'category',
    data: [],
    axisLabel: {
      formatter: function(params) {
        let val = ''
        if (params.length > 4) {
          val = params.substr(0, 4) + '...'
          return val
        } else {
          return params
        }
      }
    }
  },
  series: [
    {
      type: 'bar',
      data: [],
      itemStyle: {
        normal: {
          label: {
            show: true, //开启显示
            position: 'right', //在上方显示
            formatter: '{c}' + '%',
            textStyle: {
              //数值样式
              color: '#FFBB00',
              fontSize: 12
            }
          }
        }
      }
    }
  ]
}

export { columnarJS, resultAnalysis, topicQuantity, fractional, masterMap }
