import { render, staticRenderFns } from "./PracticeView.vue?vue&type=template&id=ac222600&scoped=true&"
import script from "./PracticeView.vue?vue&type=script&lang=js&"
export * from "./PracticeView.vue?vue&type=script&lang=js&"
import style0 from "./PracticeView.vue?vue&type=style&index=0&id=ac222600&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac222600",
  null
  
)

export default component.exports