<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>学习管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/manage/study/course/detail' }">课程管理</el-breadcrumb-item>
        <el-breadcrumb-item><span class="itClass">查看练习</span></el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="info">
      <template v-for="(data, index) in practiceInfo">
        <p :key="index">
          {{ data.title }}<span>{{ data.data }}</span>
        </p>
      </template>
    </div>
    <div class="search-fun">
      <el-select v-model="userForm.deptId" placeholder="通过部门搜索" clearable value-key="departmentId" @change="getAllPracticeUser">
        <el-option v-for="item in department" :key="item.departmentId" :label="item.name" :value="item.departmentId"> </el-option>
      </el-select>
      <el-select v-model="userForm.postId" value-key="id" placeholder="通过岗位搜索" clearable @change="getAllPracticeUser">
        <el-option v-for="item in postIdInfo" :key="item.id" :label="item.name" :value="item.id"> </el-option>
      </el-select>
      <el-select v-model="userForm.tagId" value-key="tagId" placeholder="通过人员标签搜索" clearable @change="getAllPracticeUser">
        <el-option v-for="item in tabInfo" :key="item.tagId" :label="item.name" :value="item.tagId"> </el-option>
      </el-select>
    </div>
    <div class="yt-container" :style="{ height: sectionInfo.type ? 'calc(100% - 300px)' : 'calc(100% - 130px)' }">
      <div class="yt-content">
        <el-table
          ref="paperBankList"
          class="yt-table"
          height="calc(100% - 35px)"
          :data="practiceList"
          v-loading="practiceLoading"
          element-loading-text="加载中"
          @cell-click="getPracticeInfo"
        >
          <el-table-column label="工号" prop="jobNumber" show-overflow-tooltip />
          <el-table-column label="姓名" prop="realName" show-overflow-tooltip />
          <el-table-column label="部门" prop="deptName" show-overflow-tooltip />
          <el-table-column label="岗位" prop="postName" show-overflow-tooltip />
          <el-table-column label="练习用时" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.duration }}分钟</template>
          </el-table-column>
          <el-table-column label="练习开始时间" show-overflow-tooltip>
            <template slot-scope="scope">{{ $formatTime(scope.row.startTime, 'yyyy-MM-dd hh:mm') }}</template>
          </el-table-column>
          <el-table-column label="练习结束时间" show-overflow-tooltip>
            <template slot-scope="scope">{{ $formatTime(scope.row.submitTime, 'yyyy-MM-dd hh:mm') }}</template>
          </el-table-column>
          <el-table-column label="总分" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.score }}分</template>
          </el-table-column>
          <el-table-column label="操作" width="150px">
            <template slot-scope="scope">
              <span class="tool-button" @click="handleOnclick(scope.row)">练习详情</span>
            </template>
          </el-table-column>
          <div slot="empty" id="empty-slot">
            <template v-if="!practiceLoading">
              <YTIcon :href="'#icon-zanwushuju'" style="font-size: 100px" />
            </template>
          </div>
        </el-table>
        <Page
          class="yt-page"
          :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
          :total="total"
          :current="pageNumber + 1"
          :page-size-opts="[10, 20, 40, 100]"
          :page-size="pageSize"
          @on-change="changePage"
          @on-page-size-change="changePageSize"
          show-total
          show-elevator
          show-sizer
        />
      </div>
    </div>
    <div class="situation-container" v-if="sectionInfo.type">
      <!--      <el-button type="primary" class="button" v-show="Number(activeName)" @click="exportPdf">整体报告导出</el-button>-->
      <el-tabs v-model="activeName" @tab-click="getTabInfo">
        <el-tab-pane v-for="(data, index) in situationInfo" :label="data" :name="String(index)" :key="index">
          <component :is="situation[index]" ref="situation" v-if="activeName === String(index)"></component>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import practiceApi from '@api/practice'
import departmentApi from '@api/department'
import postApi from '@api/post'
import tagApi from '@api/tag'
import PersonnelPractice from '@components/manage/course/detail/situation/PersonnelPractice'
import OverallPractice from '@components/manage/course/detail/situation/OverallPractice'
import KnowledgePractice from '@components/manage/course/detail/situation/KnowledgePractice'
export default {
  name: 'PracticeView',
  components: { YTIcon },
  data() {
    return {
      activeName: '0',
      SelecteInfo: null,
      practiceList: [],
      situationInfo: ['人员练习情况', '整体练习情况', '知识点练习情况'],
      situation: [PersonnelPractice, OverallPractice, KnowledgePractice],
      userForm: {
        deptId: null,
        postId: null,
        practiceId: null,
        tagId: null
      },
      department: [], //部门信息
      postIdInfo: [], //岗位信息
      tabInfo: [], //标签信息
      pageNumber: 0,
      pageSize: 10,
      total: 0,
      sectionInfo: null,
      practiceLoading: false,
      practiceInfo: {
        practiceName: {
          title: '练习名称：',
          data: ''
        },
        highestScore: {
          title: '练习最高分：',
          data: ''
        },
        lowestScore: {
          title: '练习最低分：',
          data: ''
        },
        averageScore: {
          title: '练习平均分：',
          data: ''
        }
      }
    }
  },
  created() {
    //section.type   1随机练习  0手动练习
    const { section } = this.$handleParams('PracticeView')
    this.sectionInfo = section
    this.userForm.practiceId = section.practiceId
    this.getPracticeStatistics(section)
    this.getDepartment()
    this.getPost()
    this.getTab()
    this.getAllPracticeUser()
  },
  methods: {
    getPracticeInfo(data) {
      this.SelecteInfo = data
      this.$nextTick(() => {
        this.$refs.situation[0].getInfo(data, this.userForm.practiceId)
      })
    },
    //导出报告
    exportPdf() {
      this.$nextTick(() => {
        this.$refs.situation[0].exportPdf()
      })
    },
    //跳转练习详情页面
    handleOnclick(data) {
      this.$router.push({
        name: 'PracticeDetail',
        params: {
          practiceId: data.practiceId,
          userId: data.userId,
          answerPaperId: data.answerPaperId
        }
      })
    },
    //获取切换信息
    getTabInfo() {
      this.$nextTick(() => {
        this.$refs.situation[0].getInfo(this.SelecteInfo, this.userForm.practiceId)
      })
    },
    //列表信息
    getAllPracticeUser() {
      practiceApi.getAllPracticeUser(this.pageNumber, this.pageSize, this.userForm).then(res => {
        if (res.code === 0) {
          this.practiceList = [...res.res.data]
          this.total = res.res.total
          if (res.res.data.length) {
            this.getPracticeInfo(res.res.data[0])
          }
        }
      })
    },
    //跳转页数
    changePage(page) {
      this.pageNumber = page - 1
      this.getAllPracticeUser()
    },
    //切换页数
    changePageSize(size) {
      this.pageSize = size
      this.getAllPracticeUser()
    },
    //一场的练习统计
    getPracticeStatistics(data) {
      practiceApi.getPracticeStatistics(data.practiceId).then(res => {
        if (res.code === 0) {
          for (let key of Object.keys(this.practiceInfo)) {
            if (Object.prototype.hasOwnProperty.call(res.res, key)) {
              this.practiceInfo[key].data = key !== 'practiceName' ? `${res.res[key]}分` : res.res[key]
            }
          }
        }
      })
    },
    //部门搜索
    getDepartment() {
      departmentApi.getdepartmentAll().then(res => {
        if (res.code === 0) {
          this.department = [...res.res]
        }
      })
    },
    //岗位查询
    getPost() {
      postApi.getAllPosts().then(res => {
        if (res.code === 0) {
          this.postIdInfo = [...res.res]
        }
      })
    },
    //人员查询
    getTab() {
      let payload = {
        typeId: 4
      }
      tagApi.searchTagByTypeId(payload).then(res => {
        if (res.code === 0) {
          this.tabInfo = [...res.res]
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/variables';
.yt-main {
  background-color: #ffffff;
  .info {
    display: flex;
    padding: 20px 0 20px 40px;
    border-bottom: 1px solid #f0f0f0;
    p {
      width: calc(100% / 4);
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      color: #333333;
    }
  }
  .search-fun {
    padding: 20px 0 0 40px;
    ::v-deep .el-select {
      margin-right: 12px;
    }
    ::v-deep .el-input__inner {
      height: 32px;
    }
    ::v-deep .el-input__icon {
      line-height: 32px;
    }
  }
  .yt-container {
    background-color: #ffffff;
  }
  .situation-container {
    padding-top: 30px;
    background-color: #f3f4f7;
    position: relative;
    ::v-deep .el-tabs__header {
      padding: 20px 0 0 0;
      margin: 0;
      background-color: #ffffff;
      .el-tabs__nav-scroll {
        margin-left: 40px;
      }
    }
    .button {
      .flexStyle(flex, center, center);
      position: absolute;
      z-index: 1;
      right: 30px;
      top: 45px;
      width: 160px;
      height: 32px;
      box-shadow: 0 3px 6px rgba(60, 100, 209, 0.5);
      border-radius: 4px;
      ::v-deep span {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
}
</style>
