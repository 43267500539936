<template>
  <div class="yt-main" id="overallPractice">
    <div class="overall-info">
      <div class="col-1">
        <div class="col-info">
          <p>基本信息</p>
          <ul>
            <li v-for="data in basicInfo" :style="{ '--color': data.color }">
              <div class="basic">
                <span>{{ data.label }}</span>
                <span>{{ data.data }}</span>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-number">
          <p>知识点下题目数量</p>
          <div ref="topicEcharts" class="columnar-s" v-loading="topicStatus" element-loading-text="加载中" />
        </div>
        <div class="col-situation">
          <p>知识点得分情况</p>
          <div ref="scoreEcharts" class="columnar-s" style="height: 220px" v-loading="practiceLoading" element-loading-text="加载中" />
        </div>
      </div>
      <div class="col-2">
        <p>分数分布</p>
        <div class="details">
          <div class="details-left" v-loading="practiceLoading" element-loading-text="加载中">
            <div ref="fractionalEcharts" class="columnar-s" />
          </div>
          <div class="details-right">
            <el-table
              ref="paperBankList"
              class="yt-table"
              height="100%"
              :data="practiceList"
              v-loading="practiceLoading"
              element-loading-text="加载中"
            >
              <el-table-column label="分数段" prop="scoreSegment" align="center" show-overflow-tooltip />
              <el-table-column label="人数" prop="personNumber" align="center" show-overflow-tooltip>
                <template slot-scope="scope">{{ scope.row.personNumber }}人</template>
              </el-table-column>
              <el-table-column label="占总人数百分比" prop="grasp" align="center" show-overflow-tooltip>
                <template slot-scope="scope">{{ scope.row.ratio * 100 }}%</template>
              </el-table-column>
              <div slot="empty" id="empty-slot">
                <template v-if="!practiceLoading">
                  <YTIcon :href="'#icon-zanwushuju'" style="font-size: 100px" />
                </template>
              </div>
            </el-table>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="title">
          <p>总体知识点掌握情况</p>
          <div class="button" @click="isPackUp = !isPackUp">
            <span v-text="isPackUp ? '展开' : '收起'"></span>
            <YTIcon :href="'#icon-open'" :style="{ transform: isPackUp ? 'rotate(0deg)' : 'rotate(180deg)' }" />
          </div>
        </div>
        <div class="details" v-show="!isPackUp">
          <div class="details-left">
            <div ref="masterEcharts" class="columnar-s" />
          </div>
          <div class="details-middle">
            <el-table ref="paperBankList" class="yt-table" height="100%" :data="graspInfo" v-loading="graspInfoLoading" element-loading-text="加载中">
              <el-table-column label="知识点名称" prop="knowledgeName" show-overflow-tooltip />
              <el-table-column label="平均掌握率" prop="avgGrasp" show-overflow-tooltip>
                <template slot-scope="scope">{{ scope.row.avgGrasp * 100 }}%</template>
              </el-table-column>
              <el-table-column label="题目数量" prop="questionCount" show-overflow-tooltip>
                <template slot-scope="scope">{{ scope.row.questionCount }}题</template>
              </el-table-column>
              <div slot="empty" id="empty-slot2">
                <template v-if="!graspInfoLoading">
                  <YTIcon :href="'#icon-zanwushuju'" style="font-size: 100px" />
                </template>
              </div>
            </el-table>
          </div>
          <div class="details-right">
            <div ref="histogramEcharts" class="columnar-s" />
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="title">
          <p>排名情况</p>
          <div class="button" @click="isPackUp1 = !isPackUp1">
            <span v-text="isPackUp1 ? '展开' : '收起'"></span>
            <YTIcon :href="'#icon-open'" :style="{ transform: isPackUp1 ? 'rotate(0deg)' : 'rotate(180deg)' }" />
          </div>
        </div>
        <div class="details" style="flex-direction: column;" v-show="!isPackUp1">
          <el-table ref="paperBankList" class="yt-table" height="calc(100% - 35px)" :data="RankList" element-loading-text="加载中">
            <el-table-column label="名次" prop="ranking" show-overflow-tooltip />
            <el-table-column label="姓名" prop="name" show-overflow-tooltip />
            <el-table-column label="练习用时" show-overflow-tooltip>
              <template slot-scope="scope">{{ scope.row.duration || '0' }}分钟</template>
            </el-table-column>
            <el-table-column label="练习开始时间" show-overflow-tooltip>
              <template slot-scope="scope">{{ $formatTime(scope.row.startTime, 'yyyy-MM-dd hh:mm') }}</template>
            </el-table-column>
            <el-table-column label="练习结束时间" show-overflow-tooltip>
              <template slot-scope="scope">{{ $formatTime(scope.row.submitTime, 'yyyy-MM-dd hh:mm') }}</template>
            </el-table-column>
            <el-table-column label="总分" prop="score" show-overflow-tooltip />
            <div slot="empty">
              <template v-if="!RankListLoading">
                <YTIcon :href="'#icon-zanwushuju'" style="font-size: 100px" />
              </template>
            </div>
          </el-table>
          <Page
            class="yt-page"
            :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
            :total="total"
            :current="pageNumber + 1"
            :page-size-opts="[10, 20, 40, 100]"
            :page-size="pageSize"
            @on-change="changePage"
            @on-page-size-change="changePageSize"
            show-total
            show-elevator
            show-sizer
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { topicQuantity, columnarJS, fractional, resultAnalysis, masterMap } from '@util/charts/Situation'
import practiceApi from '@api/practice'
import YTIcon from '@components/common/YTIcon'
import { pdfD2 } from '@util/pdfDown2'
export default {
  name: 'OverallPractice',
  components: {
    YTIcon
  },
  data() {
    return {
      total: 0,
      practiceId: null,
      pageNumber: 0,
      pageSize: 10,
      RankList: [],
      practiceList: [],
      graspInfo: [],
      RankListLoading: true,
      graspInfoLoading: true,
      isPackUp: false,
      isPackUp1: false,
      practiceLoading: true,
      scoreEchart: this.$deepCopy(columnarJS), //得分情况图
      topicQuantity: this.$deepCopy(topicQuantity), //题目数量
      fractional: this.$deepCopy(fractional), //分数分布图
      masterEchart: this.$deepCopy(resultAnalysis), //掌握图
      histogramEchart: this.$deepCopy(masterMap), //柱状掌握图
      basicInfo: {
        totalPersonNumber: { label: '练习总人数', data: '', color: '#448BFF' },
        highestScore: { label: '最高分', data: '', color: '#FF5050' },
        lowestScore: { label: '最低分', data: '', color: '#FFBB00' },
        averageScore: { label: '平均分', data: '', color: '#4CE47A' },
        medianScore: { label: '中位数', data: '', color: '#FFDB7A' }
      },
      topicEcharts: null,
      topicStatus: true,
      scoreEcharts: null,
      fractionalEcharts: null,
      masterEcharts: null,
      histogramEcharts: null
    }
  },
  mounted() {
    this.scoreEcharts = this.$echarts.init(this.$refs['scoreEcharts'])
    this.topicEcharts = this.$echarts.init(this.$refs['topicEcharts'])
    this.fractionalEcharts = this.$echarts.init(this.$refs['fractionalEcharts'])
    this.masterEcharts = this.$echarts.init(this.$refs['masterEcharts'])
    this.histogramEcharts = this.$echarts.init(this.$refs['histogramEcharts'])
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeEcharts, true)
  },
  methods: {
    //导出报告
    exportPdf() {
      pdfD2(document.getElementById('overallPractice'), `整体练习情况.pdf`)
        .then(() => {
          this.$message.success('导出成功!')
        })
        .catch(() => {
          this.$message.error('导出失败!')
        })
    },
    //获取信息
    getInfo(data, practiceId) {
      this.practiceId = practiceId
      this.getPracticeStatistics(practiceId)
      this.getKnowledgeInfo(practiceId)
      this.getknowledgeQuestion(practiceId)
      this.getDistributeScore(practiceId)
      this.getGraspInfo(practiceId)
      this.getPracticeRanking()
      window.addEventListener('resize', this.resizeEcharts, true)
    },
    //获取练习分数分布
    getDistributeScore(data) {
      practiceApi.getDistributeScore(data).then(res => {
        if (res.code === 0) {
          this.practiceLoading = false
          this.practiceList = this.$deepCopy(res.res)
          this.setFractional(res.res)
        }
      })
    },
    //图表自适应
    resizeEcharts() {
      this.scoreEcharts.resize()
      this.topicEcharts.resize()
      this.fractionalEcharts.resize()
      this.masterEcharts.resize()
      this.histogramEcharts.resize()
    },
    //获取练习人员排名情况
    getPracticeRanking() {
      practiceApi.getPracticeRanking(this.pageNumber, this.pageSize, this.practiceId).then(res => {
        if (res.code === 0) {
          this.RankListLoading = false
          this.RankList = res.res.data
          this.total = res.res.total
        }
      })
    },
    //跳转页数
    changePage(page) {
      this.pageNumber = page - 1
      this.getPracticeRanking()
    },
    //切换页数
    changePageSize(size) {
      this.pageSize = size
      this.getPracticeRanking()
    },
    //获取练习知识点掌握情况
    getGraspInfo(data) {
      practiceApi.getGraspInfo(data).then(res => {
        if (res.code === 0) {
          this.graspInfoLoading = false
          this.graspInfo = res.res
          this.setMasterEcharts(res.res)
          this.setHistogramEcharts(res.res)
        }
      })
    },
    //掌握情况图一
    setMasterEcharts(data) {
      this.masterEchart.legend.data = ['平均掌握率']
      this.masterEchart.color = ['#F16E57']
      this.masterEchart.radar.indicator = []
      this.masterEchart.series[0].data = [
        {
          value: [],
          name: '平均掌握率'
        }
      ]
      for (let datum of data) {
        this.masterEchart.radar.indicator.push({ name: datum.knowledgeName, max: 100 })
        this.masterEchart.series[0].data[0].value.push(datum.avgGrasp * 100)
      }
      this.masterEcharts.setOption(this.masterEchart)
    },
    //掌握情况图二
    setHistogramEcharts(data) {
      this.histogramEchart.series[0].data = []
      this.histogramEchart.yAxis.data = []
      for (let datum of data) {
        this.histogramEchart.yAxis.data.push(datum.knowledgeName)
        this.histogramEchart.series[0].data.push(datum.avgGrasp * 100)
      }
      this.histogramEcharts.setOption(this.histogramEchart)
    },
    //分数分布图
    setFractional(data) {
      this.fractional.xAxis[0].data = []
      this.fractional.series[0].data = []
      this.fractional.series[1].data = []
      for (let datum of data) {
        this.fractional.xAxis[0].data.push(datum.scoreSegment)
        this.fractional.series[0].data.push(datum.personNumber)
        this.fractional.series[1].data.push(datum.ratio * 100)
      }
      this.fractional.yAxis[0].max = data.sort((a, b) => b.personNumber - a.personNumber)[0].personNumber
      this.fractional.yAxis[1].max = data.sort((a, b) => b.ratio - a.ratio)[0].ratio * 100
      this.fractionalEcharts.setOption(this.fractional)
    },
    //获取练习知识点题型数量
    getknowledgeQuestion(data) {
      this.topicQuantity.series[0].data = []
      practiceApi
        .getknowledgeQuestion(data)
        .then(res => {
          if (res.code === 0) {
            for (let score of res.res) {
              this.topicQuantity.series[0].data.push({
                value: score.questionCount,
                name: score.knowledgeName
              })
              this.topicStatus = false
            }
          }
        })
        .finally(() => {
          this.topicEcharts.setOption(this.topicQuantity)
        })
    },
    //一场的练习统计
    getPracticeStatistics(data) {
      practiceApi.getPracticeStatistics(data).then(res => {
        if (res.code === 0) {
          for (let key of Object.keys(this.basicInfo)) {
            if (Object.prototype.hasOwnProperty.call(res.res, key)) {
              this.basicInfo[key].data = key !== 'totalPersonNumber' ? `${res.res[key]}分` : `${res.res[key]}人`
            }
          }
        }
      })
    },
    //获取练习知识点得分情况
    getKnowledgeInfo(data) {
      this.scoreEchart.xAxis.data = []
      this.scoreEchart.series[0].data = []
      this.scoreEchart.series[1].data = []
      practiceApi
        .getKnowledgeInfo(data)
        .then(res => {
          if (res.code === 0) {
            for (let score of res.res) {
              this.scoreEchart.xAxis.data.push(score.knowledgeName)
              this.scoreEchart.series[0].data.push(score.rightScore)
              this.scoreEchart.series[1].data.push(score.wrongScore)
            }
          }
        })
        .finally(() => {
          this.scoreEcharts.setOption(this.scoreEchart)
        })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/variables';
.overall-info {
  padding: 10px 10px 20px;
  .columnar-s {
    height: 100%;
    width: 100%;
  }
  .title {
    .flexStyle(flex, space-between, center);
    margin-bottom: 5px;
    .button {
      .flexStyle(flex, center, center);
      width: 53px;
      height: 25px;
      background: #f7f7f7;
      border: 1px solid #e6e6e6;
      border-radius: 13px;
      user-select: none;
      cursor: pointer;
      span {
        font-size: 12px;
        line-height: 17px;
        color: #888888;
        margin-right: 4px;
      }
      svg {
        transition: all 1s ease-in-out;
        fill: #888888;
      }
    }
  }
  .col-1 {
    display: flex;
    p {
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      color: #333333;
    }
    .col-info {
      width: 24%;
      min-height: 220px;
      background: #ffffff;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
      padding: 10px;
      ul {
        margin-top: 18px;
        li {
          display: flex;
          border-bottom: 1px solid #f0f0f0;
          margin-bottom: 22px;
          &:before {
            content: '\2022';
            color: var(--color);
            width: 4px;
            height: 4px;
          }
          .basic {
            margin-left: 5px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            span {
              line-height: 17px;
              color: #333333;
            }
          }
        }
      }
    }
    .col-number {
      width: 33%;
      min-width: 390px;
      background: #ffffff;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
      margin-left: 10px;
      padding: 10px;
      overflow: hidden;
    }
    .col-situation {
      width: 42%;
      min-width: 490px;
      background: #ffffff;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
      margin-left: 10px;
      padding: 10px;
    }
  }
  .col-2 {
    margin-top: 10px;
    padding: 10px;
    background: #ffffff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    p {
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      color: #333333;
    }
    .details {
      display: flex;
      min-height: 340px;
      .details-left {
        flex: 1;
      }
      .details-right {
        flex: 1;
      }
    }
  }
  .col-3 {
    margin-top: 10px;
    padding: 10px;
    background: #ffffff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    p {
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      color: #333333;
    }
    .details {
      display: flex;
      height: 300px;
      .details-left {
        flex: 1;
      }
      .details-middle {
        width: 249px;
      }
      .details-right {
        flex: 1;
      }
    }
  }
  .col-4 {
    margin-top: 10px;
    padding: 10px;
    background: #ffffff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    p {
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      color: #333333;
    }
    .details {
      display: flex;
      min-height: 350px;
      .details-left {
        width: calc((100% / 2) - 10px);
      }
      .details-right {
        width: calc((100% / 2) - 10px);
        margin-left: 20px;
      }
    }
  }
}
</style>
